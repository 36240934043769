import Icon from "@material-ui/core/Icon";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { ChangeLogCount, ChangeLogItem, getChangelogCounts, getChangelogItems } from "../../api/changelog";
import Card from "../../components/Card";
import FooterButton from "../../components/FooterButton";
import InternalButton from "../../components/InternalButton";
import ChangeLogTable from "./ChangeLogTable";

const Column = styled.div`
  display: grid;
  grid-auto-columns: max-content;
`;

const Content = styled.div`
  margin-top: 5em;
`;

const ChangelogHeader = styled.div`
  height: 33px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 1em;
`;

export enum ChangeLogType {
  ALL = "all",
  TARGET = "target",
  BUDGET = "budget",
  BLOCKED = "blockedProduct",
  BOOSTED = "boostedProduct",
  CATEGORY = "category",
  LOCKED = "lockedProduct",
  MPA_UPLOAD = "mpaUpload",
  PRODUCT_VALUE_SET = "productValueSet",
  CUSTOM_LABEL_EXCLUSIONS = "ignoreProductsCustomLabelMapping",
}

const Changelog = () => {
  const [page, setPage] = useState<number>(0);
  const [changeLogType, setChangeLogType] = useState<ChangeLogType>(ChangeLogType.ALL);
  const [counts, setCounts] = useState<ChangeLogCount>({
    blockedProduct: 0,
    boostedProduct: 0,
    category: 0,
    lockedProduct: 0,
    mpaUpload: 0,
    productValueSet: 0,
    budget: 0,
    target: 0,
    all: 0,
    ignoreProductsCustomLabelMapping: 0,
  });
  const [changeLogs, setChangeLogs] = useState<ChangeLogItem[]>([]);
  const [isLastPage, setIsLastPage] = useState<boolean>(true);
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const startDate = DateTime.now().minus({ years: 1 }).toJSDate();
  const endDate = DateTime.now().toJSDate();

  const changeFilter = (changeLogType: ChangeLogType): void => {
    setPage(0);
    setChangeLogType(changeLogType);
  };

  useEffect(() => {
    let isMounted = true;

    async function getCounts() {
      const counts = await getChangelogCounts(startDate, endDate);
      if (isMounted) setCounts(counts);
    }
    getCounts();
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let isMounted = true;

    async function getChangelogs() {
      const items = await getChangelogItems(page * 20, 20, startDate, endDate, changeLogType);
      if (isMounted) setChangeLogs(items);
    }
    getChangelogs();
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [page, changeLogType]);

  useEffect(() => {
    let isMounted = true;
    async function getIsLastPage() {
      if (isMounted) {
        const itemCount = page * 20 + changeLogs.length;
        setIsLastPage(itemCount >= counts[changeLogType]);
      }
    }
    getIsLastPage();
    return () => {
      isMounted = false;
    };
  }, [page, changeLogs, counts, changeLogType]);

  return (
    <Content>
      <Column>
        <ChangelogHeader>
          <ButtonWrapper>
            <InternalButton
              active={changeLogType === ChangeLogType.ALL}
              onClick={() => changeFilter(ChangeLogType.ALL)}
            >
              {`All Changes (${counts.all})`}
            </InternalButton>
            <InternalButton
              active={changeLogType === ChangeLogType.TARGET}
              onClick={() => changeFilter(ChangeLogType.TARGET)}
            >
              {`Target Changes (${counts.target})`}
            </InternalButton>
            <InternalButton
              active={changeLogType === ChangeLogType.BUDGET}
              onClick={() => changeFilter(ChangeLogType.BUDGET)}
            >
              {`Budget Changes (${counts.budget})`}
            </InternalButton>
            <InternalButton
              active={changeLogType === ChangeLogType.BLOCKED}
              onClick={() => changeFilter(ChangeLogType.BLOCKED)}
            >
              {`Blocked Products (${counts.blockedProduct})`}
            </InternalButton>
            <InternalButton
              active={changeLogType === ChangeLogType.BOOSTED}
              onClick={() => changeFilter(ChangeLogType.BOOSTED)}
            >
              {`Boosted Products (${counts.boostedProduct})`}
            </InternalButton>
            <InternalButton
              active={changeLogType === ChangeLogType.LOCKED}
              onClick={() => changeFilter(ChangeLogType.LOCKED)}
            >
              {`Locked Products (${counts.lockedProduct})`}
            </InternalButton>
            <InternalButton
              active={changeLogType === ChangeLogType.CATEGORY}
              onClick={() => changeFilter(ChangeLogType.CATEGORY)}
            >
              {`Category Changes (${counts.category})`}
            </InternalButton>
            <InternalButton
              active={changeLogType === ChangeLogType.MPA_UPLOAD}
              onClick={() => changeFilter(ChangeLogType.MPA_UPLOAD)}
            >
              {`MPA Uploads (${counts.mpaUpload})`}
            </InternalButton>
            <InternalButton
              active={changeLogType === ChangeLogType.PRODUCT_VALUE_SET}
              onClick={() => changeFilter(ChangeLogType.PRODUCT_VALUE_SET)}
            >
              {`Product Value Set (${counts.productValueSet})`}
            </InternalButton>
            <InternalButton
              active={changeLogType === ChangeLogType.CUSTOM_LABEL_EXCLUSIONS}
              onClick={() => changeFilter(ChangeLogType.CUSTOM_LABEL_EXCLUSIONS)}
            >
              {`Custom Label Exclusions (${counts.ignoreProductsCustomLabelMapping})`}
            </InternalButton>
          </ButtonWrapper>
        </ChangelogHeader>
        <Card>
          <ChangeLogTable page={page} changeLogType={changeLogType} changeLogs={changeLogs}>
            <FooterButton
              disabled={page < 1}
              onClick={() => {
                page > 0 && setPage(page - 1);
              }}
            >
              <Icon className="material-icons-outline">{"<<"}</Icon>
            </FooterButton>
            <>Current Page: {page + 1}</>
            <FooterButton
              disabled={isLastPage}
              onClick={() => {
                !isLastPage && setPage(page + 1);
              }}
            >
              <Icon className="material-icons-outlined">{">>"}</Icon>
            </FooterButton>
          </ChangeLogTable>
        </Card>
      </Column>
    </Content>
  );
};

export default Changelog;
